"use client";

/* eslint-disable import/no-unresolved */
import React from "react";
import Script from "next/script";
import { GoogleTagManager } from "@next/third-parties/google";
import { Nunito } from "next/font/google";

const nunito = Nunito({
  subsets: ["latin-ext"],
  display: "swap",
  weight: ["400", "600", "700"],
  adjustFontFallback: false,
  fallback: ["Arial"],
  preload: false,
});

const consentText = {
  pl: "<strong>Audioteka</strong> jest częścią holdingu Wirtualna Polska. <br><br>",
  cs: "<strong>Audioteka</strong> je součástí holdingu Wirtualna Polska. <br><br>",
  de: "<strong>Audioteka</strong> ist Teil der Wirtualna Polska-Gruppe. <br><br>",
  en: "<strong>Audioteka</strong> is part of the Wirtualna Polska holding. <br><br>",
  lt: "<strong>Audioteka</strong> yra Wirtualna Polska holdingo dalis. <br><br>",
  sk: "<strong>Audioteka</strong> je súčasťou holdingu Wirtualna Polska. <br><br>",
} as const;

type ValidLangs = keyof typeof consentText;

function isValidLang(lang: string): lang is ValidLangs {
  return lang in consentText;
}

export default function WelcomeLayout({ children }: { children: React.ReactNode }) {
  const [lang, setLang] = React.useState<ValidLangs>("en");

  React.useEffect(() => {
    const currentLang = navigator.language.split("-")[0];

    if (isValidLang(currentLang)) {
      setLang(currentLang);
      window.wp_consent_text = consentText[currentLang];
    }
  }, []);

  return (
    <html lang={lang} className={nunito.className}>
      <body>
        {children}

        <Script id="wpjslib-config">
          {`
              var wp_onepager = true;
              var wp_sn = 'audioteka';
              var wp_consent_color = '#33ADFF';
              var wp_consent_logo = 'https://atkcdn.com/audioteka/global/logo-transparent.png';
              var wp_consent_blur = 0;
              window.wp_cookie_info = false;             
              `}
        </Script>
        <Script async crossOrigin="" noModule defer src="https://std.wpcdn.pl/wpjslib/wpjslib-lite.js" id="wpjslib" />
        <Script async crossOrigin="" type="module" src="https://std.wpcdn.pl/wpjslib6/wpjslib-lite.js" id="wpjslib6" />
        <GoogleTagManager gtmId="GTM-N25LTC" />
      </body>
    </html>
  );
}
